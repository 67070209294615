<template>
  <div id="data-list-list-view" class="data-list-container">
    <div class="vs-con-table">
      <header class="header-table vs-table--header">
        <div class="con-input-search vs-table--search">
          <input
            type="text"
            class="input-search vs-table--search-input"
            v-model="search"
            placeholder="ابحث بإسم الزبون"
            @blur="handleSearch"
          /><i class="vs-icon notranslate icon-scale material-icons null"
            >search</i
          >
        </div>
      </header>
      <vs-table ref="table" :data="orders">
        <template slot="thead">
          <vs-th>رقم الطلب</vs-th>
          <vs-th sort-key="total_amount">المبلغ الإجمالي</vs-th>
          <vs-th sort-key="delivery_date">تاريخ التوصيل</vs-th>
          <vs-th>للزبون</vs-th>
          <vs-th sort-key="order_status_id">حالة الطلب</vs-th>
          <vs-th sort-key="order_status_id">تاريخ الطلب</vs-th>
          <vs-th sort-key="city">المدينة</vs-th>
          <vs-th>العمليات</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <p class="product-name font-medium truncate">{{ tr.id }}</p>
              </vs-td>

              <vs-td>
                <p class="product-category">{{ tr.total_amount }}LYD</p>
              </vs-td>

              <vs-td>
                <vs-chip class="product-order-status">
                  {{ tr.delivery_date ? tr.delivery_date : "غير محدد" }}
                </vs-chip>
              </vs-td>

              <vs-td>
                {{ tr.user.name }}
              </vs-td>

              <vs-td>
                <vs-chip
                  transparent
                  :color="getOrderStatusColor(Number(tr.order_status_id))"
                  class="product-order-status"
                >
                  {{ tr.statu.status }}</vs-chip
                >
              </vs-td>
              <vs-td> {{ tr.created_at }}</vs-td>
              <vs-td> {{ tr.address.city.name }}</vs-td>


              <vs-td class="whitespace-no-wrap">
                <router-link
                  :to="{
                    name: 'edit-order',
                    params: { id: tr.id },
                    query: { page: pagination.page, tab: active_tab },
                  }"
                >
                  <feather-icon
                    icon="EditIcon"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  />
                </router-link>

                
                <feather-icon
                  icon="ArchiveIcon"
                  svgClasses="w-5 h-5 hover:text-warning stroke-current"
                  class="ml-2"
                  @click.stop="confirm(tr.id)"
                />
                <router-link
                  :to="{ name: 'e-commerce-invoice',
                  params: { id: tr.id } }"
                >
                <feather-icon
                  icon="PrinterIcon"
                  svgClasses="w-5 h-5 hover:text-success stroke-current"
                  class="ml-2"
                />
                </router-link>

              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
      <vs-pagination
        class="mt-4"
        :total="Number(pagination.lastPage)"
        v-model="pagination.page"
        @change="$emit('getOrders', pagination.page, status)"
      ></vs-pagination>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    orders: {
      type: Array,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    active_tab: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      order_id: "",
      search: "",
    };
  },
  methods: {
    ...mapActions("orders", ["archiveOrder"]),
    getOrderStatusColor(status) {
      switch (status) {
        case 1:
          return "warning";
        case 2:
          return "#f7cb73";
        case 3:
          return "#af78ff";
        case 4:
          return "#50d2a0";
        case 5:
          return "success";
        case 6:
          return "dark";
        case 7:
          return "danger";
        default:
          return "dark";
      }
    },
    confirm(id) {
      this.order_id = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "تأكيد",
        text: "هل انت متأكد من ارشفة هذا الطلب؟",
        acceptText: "تأكيد",
        cancelText: "الغاء",
        accept: this.archiveData,
      });
    },
    archiveData() {
      this.$vs.loading();
      this.archiveOrder(this.order_id)
        .then((response) => {
          this.$vs.loading.close();
          this.successDialog(response.data.message);
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.errorDialog(error);
        });
    },
    printInvoice(tr) {
      localStorage.setItem("order_id", tr.id);
      this.$router.push({ name: "e-commerce-invoice" });
    },
    editData(tr) {
      this.$router.push({
        name: "edit-order",
        params: { id: tr.id },
        query: { page: this.pagination.page, tab: this.active_tab },
      });
    },
    handleSearch() {
      this.$emit("search", this.search);
    },
    addNewData() {
      this.$router.push({ name: "new-order" });
    },
  },
  mounted(){
  }
};
</script>

<style>
</style>
