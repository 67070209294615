<template>
  <vx-card>
    <div slot="no-body" class="tabs-container px-6 pt-6">
      <div
        @click="$router.push({ name: 'new-order' })"
        v-if="role == 'super-admin'"
      >
        <vs-button
          class="
            btn-add-new
            p-3
            mb-4
            mr-4
            rounded-lg
            cursor-pointer
            flex
            items-center
            justify-center
          "
        >
          <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
          <span class="ml-2">اضافة طلبية</span>
        </vs-button>
      </div>
      <vs-tabs :value="activeTab" >
        <vs-tab
          label="قيد المراجعة"
          icon="pets"
          @click="UpdateTab(0)"
        >
          <orders-table
            :orders="orders"
            status="1"
            :pagination="{ page, lastPage }"
            active_tab=0
            @getOrders="getOrdersPagination"
             @search="getOrdersSearch"
          ></orders-table>
        </vs-tab>
        <vs-tab
          label="معلق"
          icon-pack="feather"
          icon="icon-alert-circle"           
          @click="UpdateTab(1)"

        >
         <orders-table
            :orders="orders"
            status="2"
            active_tab=1
            :pagination="{ page, lastPage }"
             @getOrders="getOrdersPagination"
              @search="getOrdersSearch"
          ></orders-table>
        </vs-tab>
        <vs-tab
          label="قيد التجهيز"
          icon-pack="feather"
          icon="icon-box"
          @click="UpdateTab(2)"
        >
        <orders-table 
            :orders="orders"
            status="3"
            
            active_tab=2
            :pagination="{ page, lastPage }"
            @getOrders="getOrdersPagination"
             @search="getOrdersSearch"
          ></orders-table>
        </vs-tab>
        <vs-tab
          label="قيد التوصيل"
          icon="dashboard"
          @click="UpdateTab(3)"
        >
         <orders-table
            :orders="orders"
            status="4"
            
            active_tab=3
            :pagination="{ page, lastPage }"
             @getOrders="getOrdersPagination"
              @search="getOrdersSearch"
          ></orders-table>
        </vs-tab>
        <vs-tab
          label="تم التوصيل"
          icon="account_circle"
          @click="UpdateTab(4)"
        >
         <orders-table
            :orders="orders"
            status="5"

            
            active_tab=4

            :pagination="{ page, lastPage }"
             @getOrders="getOrdersPagination"
              @search="getOrdersSearch"
          ></orders-table>
        </vs-tab>
        <vs-tab
          label=" تم الإلغاء"
          icon-pack="feather"
          icon="icon-user-x"
          @click="UpdateTab(5)"
        >
         <orders-table
            :orders="orders"
            status="6"

            
            active_tab=5

            :pagination="{ page, lastPage }"
             @getOrders="getOrdersPagination"
             @search="getOrdersSearch"
          ></orders-table>
        </vs-tab>
        <vs-tab
          label="تم الرفض"
         icon-pack="feather"
          icon="icon-x-circle"
          @click="UpdateTab(6)"
        >
         <orders-table
            :orders="orders"
            status="7"

            
            active_tab=6

            :pagination="{ page, lastPage }"
             @getOrders="getOrdersPagination"
            @search="getOrdersSearch"
          ></orders-table>
        </vs-tab>
      </vs-tabs>
    </div>
  </vx-card>
</template>

<script>
import EmptyState from "@/views/emptyState.vue";
import OrdersTable from "./components/orders-table.vue";
import { mapActions } from "vuex";
export default {
  components: {
    EmptyState,
    OrdersTable,
  },
  data() {
    return {
      colorx: "#8B0000",
      page: Number(this.$route.query.page)||1,
      status:'',
      order_id: "",
      activeTab: Number(this.$route.query.tab) || '0',
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.orders.length;
    },
    orders() {
      return this.$store.state.orders.orders;
    },
    lastPage() {
      return this.$store.state.orders.pagination;
    },
  },
  methods: {
    UpdateTab(e){
     this.activeTab = e;
     this.page = 1
    },
    getOrdersPagination(page,status){
      this.page = page;
      this.status = status;
      this.getData(`page=${page}&filter[order_status_id]=${status}`);
    },
    getOrdersSearch(search){
      this.getData(`page=${this.page}&filter[order_status_id]=${this.status}&filter[user.name]=${search}`);
    },
    ...mapActions("orders", ["fetchOrders", "archiveOrder"]),
    handleSort(key, active) {
      active = active == "asc" ? "-" : "";
      let sort = active + key;
      let page = this.page;
      this.fetchOrders({ page, sort });
    },
    getData(params) {
      this.fetchOrders(params);
    },
  },
  mounted() {
    this.isMounted = true;
  },
  created() {
    this.getData(`page=${this.page}&filter[order_status_id]=1`);

  },
};
</script>

<style >
.ul-tabs {
  justify-content: space-around;
}
</style>